import { useMediaQuery } from 'react-responsive';
import React from 'react';

export const HELPLINE_NUMBER = 'allianceair Helpline No.: 1800 XXXXX';
export const PHONE_NUMBER = '+91 44 4255 4255/+91 44 3511 3511';
export const EMAIL = 'support@allianceair.in';
export const COPYRIGHT = 'Copyright 2020 allianceair. All rights reserved.';

export const ADDRESS = [
  {
    icon: '/contactus/registeredOffice.ico',
    name: 'Registered Office',
    post: (
      <div>
        <p>CIN: U62200MH2014PTC260145</p>
        <p>304, 3RD Floor, Cosmos Court-Premises SCO Ltd.,</p>
        <p>S V Road Vile Parle (W), Mumbai - 400056,</p>
        <p>Maharashtra, India</p>
      </div>
    ),
    cordinates: '0000 0000',
    image: '/contactus/registerdOfficeAddress.png',
  },
  {
    icon: '/contactus/corporateOffice.ico',
    name: 'Corporate Office',
    post: (
      <div>
        <p>Killa No. 13, 3rd Floor, Begumpur, Khatola,</p>
        <p>Gurugram - 122001, Haryana, India</p>
      </div>
    ),
    cordinates: '28.4181479 77.0070347',
    image: '/contactus/corporateOfficeAddress.png',
  },
  {
    icon: '/contactus/feedback.png',
    name: 'Guest Feedback',
    post:
(
  <div>
    For any feedback/suggestions/complaints or to reach out to our Guest Relations Team, please write to us at
    {' '}
    <a href="mailto:guest.relations@allianceair.in">guest.relations@allianceair.in</a>
  </div>
),
    cordinates: '28.4181479 77.0070347',
    image: '/contactus/corporateOfficeAddress.png',
  },
  {
    icon: '/contactus/helpline.png',
    name: 'Guest Helpline',
    post:
(
  <div>
    For any queries related to your travel, call our call center on:
    {' '}
    {PHONE_NUMBER}
  </div>
),
    cordinates: '28.4181479 77.0070347',
    image: '/contactus/corporateOfficeAddress.png',
  },
  {
    icon: '/contactus/grievance.png',
    name: 'Grievance Redressal',
    post:
(
  <div>
    <p>
      In compliance with The Civil Aviation Requirement, Section -3, Air Transport, Series M, Part IV. You may write to the following:
    </p>
    <p>
      Nodal Officer, Ms. Kanchan Gidwani at
      {' '}
      <a href="mailto:nodalofficer@allianceair.in">nodalofficer@allianceair.in</a>
    </p>
    <p>
      Appellate authority, Mr. Manish Koul at
      {' '}
      <a href="mailto:appellateauthority@allianceair.in">appellateauthority@allianceair.in</a>
    </p>
    <p>You may contact them from Monday – Friday between 0930hrs – 1830hrs.</p>
  </div>
),
    cordinates: '28.4181479 77.0070347',
    image: '/contactus/corporateOfficeAddress.png',
  },
  {
    icon: '/contactus/group-sales.png',
    name: 'Group Sales',
    post:
(
  <div>
    If you have more than 9 passengers travelling, please contact us for special group fares at
    {' '}
    <a href="mailto:groupdesk@allianceair.in">groupdesk@allianceair.in</a>
  </div>
),
    cordinates: '28.4181479 77.0070347',
    image: '/contactus/corporateOfficeAddress.png',
  },
  {
    icon: '/contactus/advertise.png',
    name: 'Advertise with Us',
    post:
(
  <div>
    For any enquiries related to Sales, Promotions, Media or Ancillary Tie-ups – please email us at
    {' '}
    <a href="mailto:info@allianceair.in">info@allianceair.in</a>
  </div>
),
    cordinates: '28.4181479 77.0070347',
    image: '/contactus/corporateOfficeAddress.png',
  },
];

export const SOCIAL = [
  {
    name: 'facebook',
    icon: '/social/facebook.ico',
    url: '/',
  },
  {
    name: 'twitter',
    icon: '/social/twitter.ico',
    url: '/',
  },
  {
    name: 'instagram',
    icon: '/social/instagram.ico',
    url: '/',
  },
  {
    name: 'linkedin',
    icon: '/social/linkedin.ico',
    url: '/',
  },
  {
    name: 'youtube',
    icon: '/social/youtube.ico',
    url: '/',
  },
];

export const ABOUTUS = [
  {
    image: '/aboutus/corporate.jpg',
    headerIcon: '/aboutus/corporate.ico',
    header: 'CORPORATE INFORMATION',
  },
  {
    image: '/aboutus/vision.jpg',
    headerIcon: '/aboutus/vision.ico',
    header: 'VISION & MISSION',
  },
  {
    image: '/aboutus/values.png',
    headerIcon: '/aboutus/values.ico',
    header: 'BIG VALUES',
  },
];

export const BOOKCITIES = [
  {
    name: 'Bhopal',
    text: '',
    image: '/cities/Bhopal.png',
  },
  {
    name: 'Indore',
    text: '',
    image: '/cities/Indore.png',
  },
  {
    name: 'Jabalpur',
    text: '',
    image: '/cities/Jabalpur.png',
  },

];

export const BANNERS = [
  // // without URL
  // {
  //   image: '/banners/banner1.png',
  // },
  // // with URL
  {
    image: '/banners/launch-banner.jpg',
  },
  {
    image: '/banners/shillong-banner.jpg',
    url: '/delhi-shillong',
  },
  {
    image: '/banners/ahemdabad-banner.jpg',
  },
  {
    image: '/banners/indore-banner.jpg',
  },
];

export function useGetMedia() {
  const res = {
    0: useMediaQuery({ minWidth: 0, maxWidth: 575 }),
    1: useMediaQuery({ minWidth: 576, maxWidth: 767 }),
    2: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    3: useMediaQuery({ minWidth: 992, maxWidth: 1199 }),
    4: useMediaQuery({ minWidth: 1200 }),
  };
  return Object.keys(res).filter((key) => res[key] === true)[0];
}

export const SQIVA_URLS = [
  'manage-booking',
  'web-checkin',
];

export const FOOTER_SECTIONS = {
  FOOTER_LINKS: 'footer-links',
  OUR_ROUTES: 'our-routes',
  TOP_DESTINATIONS: 'top-destinations',
  BELOW_FOOTER: 'below-footer',
};
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
