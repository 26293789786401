/* eslint-disable max-len */
import React from 'react';
import './FAQItem.scss';
import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL } from '../../../config/config';

export default function SpecialMedicalCondition({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      <CardHolder
        id="expectantMothers"
        eventKey="expectantMothers"
        selectedEventKey={eventKey}
        header="Until when are Expectant Mothers permitted to fly?"
      >
        <p>We understand that pregnancy is not a medical condition unless there are known medical complications which can endanger the life of mother or the child. However, we request you to kindly take note of the following:</p>
        <p>Normal pregnancies:</p>
        <ul>
          <li>Expectant mothers may be permitted to fly on allianceair flights till the end of 32 weeks of pregnancy, provided there are no prior complications.</li>
          <li>Fit to Fly certificate from the treating obstetrician, dated not more than 7 days before the date of travel, is required only if the pregnancy is between the 28th and 32nd week.</li>
        </ul>
        <p>
          Multiple (twins, triplets etc.) pregnancies:
        </p>
        <ul>
          <li>Expectant mothers may be permitted to fly on allianceair flights only till the end of 32 weeks of pregnancy.</li>
          <li>Fit to Fly certificate from the treating obstetrician, dated not more than 7 days before the date of travel, is required only if the pregnancy is between 28th & 32nd  week.</li>
        </ul>
        <p>
          Complicated pregnancies:
        </p>
        <ul>
          <li>Expectant mothers shall not be allowed to travel beyond 36 weeks of pregnancy  in case of complicated pregnancy.</li>
          <li>Fit to Fly certificate from the treating obstetrician, dated not more than 3 days before the date of travel, is required only if the pregnancy is between 28th & 32th week.</li>
          <li>In addition, medical clearance is given by the company doctor who will discuss the case on an individual basis with the passenger’s doctor/or will check all the medical documents of the passenger (pregnant lady) in case treating obstetrician is not contactable.</li>
        </ul>
      </CardHolder>

      <CardHolder
        id="informMedCondition"
        eventKey="informMedCondition"
        selectedEventKey={eventKey}
        header="Do I need to inform allianceair if I have any medical condition?"
      >
        <p>
          Guests who are ailing from any particular medical condition can travel on allianceair after giving prior medical information as per the medical form provided on the allianceair Website, in order for allianceair to provide complete assistance.
        </p>
        <ul>
          <li>Medical Information must be provided to allianceair at least 72 hours prior to the scheduled departure of the flight.</li>
          <li>The guest must also inform allianceair regarding any hospitalisation requirement upon arrival.</li>
          <li>In case a Guest will be accompanied by a safety assistant during travel then his/her name and contact details must also be shared with allianceair.</li>
        </ul>
      </CardHolder>

      <CardHolder
        id="crewMedAssistance"
        eventKey="crewMedAssistance"
        selectedEventKey={eventKey}
        header="Will the cabin crew be able to provide me with any medical assistance?"
      >
        <ul>
          <li>allianceair would like to mention that our cabin crews are not authorized to give special assistance (e.g. lifting etc.). Further they are trained only in first-aid and are not permitted to administer any injections or to give any medication.</li>
          <li>
            A guest who has agreed to our
            <a href={`${HOME_URL}/coc`}>Conditions of Carriage</a>
            {' '}
            agrees and understands that he/she has accepted to undertake the travel on allianceair on his/her own will and volition, and further agrees that allianceair will not be responsible for any liability other than the liability as specified in the
            <a href={`${HOME_URL}/coc`}>Conditions of Carriage</a>
            .
          </li>
        </ul>
      </CardHolder>

      <CardHolder
        id="carryWheelChair"
        eventKey="carryWheelChair"
        selectedEventKey={eventKey}
        header="What rules apply for passengers carrying their own wheelchair?"
      >
        <strong>Can I carry my own wheelchair?</strong>
        <p>If guests wish to use their own wheelchair at the airport, they are permitted to do so up to the aircraft, whereupon it will be stored in the baggage hold area. Guests with a disability may use their own manual powered wheelchair till the aircraft door.</p>
        <p>If guests wish to check-in their own wheel chair, allianceair shall provide assistance to dismantle and/or reassemble the wheelchair under the supervision of the guest. However, allianceair shall not be liable for any damage arising while handling the wheelchair.</p>
        <p>Checked-in wheelchairs will be returned to the guest at the destination airport. We recommend carrying suitable travel insurance for any such equipment.</p>

        <strong>Can I carry my own electronic powered wheelchairs?</strong>
        <p>
          Unfortunately electronic powered wheelchair(s) with a wet-cell battery shall not be accepted since it’s corrosive in nature and is considered to be a ‘Dangerous Good’. Electronic powered wheelchair(s) with lithium or non spillable battery shall not be allowed beyond the flight check-in counter, due to security reasons. Electronic wheelchairs will be dismantled at our flight check-in counters and Guests will need to carry something to seal the battery terminals to avoid damage to the battery.
        </p>

        <strong>Where will my wheelchair be returned back to me?</strong>
        <p>
          If the wheelchair is kept in the hold, we'll make every effort to return it to the guest as close to the aircraft door as possible.If this is not possible, we will make sure there is a wheelchair available to assist guest to the baggage reclaim area. We can arrange assistance between the gate and the arrivals hall if the guest would like this service. Depending on the airport, this will either be by electric buggy, airport wheelchair or accessible buses.
        </p>

        <strong>Liability for damage to wheelchair</strong>
        <p>
          In the event of loss or damage, mobility aids or any equipment relating to the disability which are placed into the checked-in luggage is not covered for anything more than the maximum liability under allianceair’s
          {' '}
          <a href={`${HOME_URL}/coc`}>Conditions of Carriage</a>
          .
        </p>

        <strong>Wheelchair inventory per flight</strong>
        <p>
          Wheelchair assistance can be provided on first come first serve basis, if inventory available, hence we recommend passengers to place a request for wheelchair atleast 48 hours prior to schedule departure of the flight.
        </p>
      </CardHolder>

      <CardHolder
        id="wheelChairAssistance"
        eventKey="wheelChairAssistance"
        selectedEventKey={eventKey}
        header="How can I opt for wheelchair assistance at the airport?"
      >
        <strong>Wheelchair Assistance</strong>
        <p>
          We have wheelchairs available for use at all airport locations to assist with transportation from check-in to the boarding gate, making connections (if applicable) and from the aircraft to the arrivals hall at the destination. However, this depends upon the availability of wheelchairs on flight.
        </p>
        <strong>Process to opt for assistance</strong>
        <p>
          In order to make necessary arrangements we request you to call us 48 hours prior minimum. Guests can request this service when making their flight booking via our call centre or they can call us after making their booking on our website.
        </p>
        <strong>On-Board Services for passengers with wheelchair assistance</strong>
        <p>
          If a guest is travelling without safety assistant, allianceair shall be happy providing below mentioned assistance:
        </p>
        <ul>
          <li>A meet and assist service to the guest to assist in boarding, deplaning, flight connections and check-in / transfer / reclaiming of checked-in bags.</li>
          <li>Stowing of hand baggage</li>
          <li>Opening any parts of guest’s on board meal</li>
          <li>On board individual safety briefings if requested by guest</li>
        </ul>
        <p>
          allianceair regrets its inability to assist with following:
        </p>
        <ul>
          <li>
            Assist the guest with personal care and toileting needs before, after and during the flight.
          </li>
        </ul>
      </CardHolder>

      <CardHolder
        id="carryOxygen"
        eventKey="carryOxygen"
        selectedEventKey={eventKey}
        header="What rules apply if I want to carry a Portable Oxygen Concentrator (“POC”)?"
      >
        <strong>Carriage of a POC</strong>
        <ul>
          <li>A request for carriage or use of the POC on board needs to be made to allianceair a minimum forty eight (48) hours in advance from the scheduled departure time.</li>
          <li>Only Portable Oxygen Concentrator (“POC”), approved by Federal Aviation Administration (“FAA”) is permitted on board allianceair flights either as checked-in or hand baggage, free of cost, subject to certain conditions.</li>
          <li>The weight of a POC to be carried on board should not be more than 7 Kg</li>
          <li>Such Guests must be capable of seeing, hearing and understanding the POC device warnings and should be in a position to take appropriate action, without assistance, in response to those warnings</li>
          <li>During taxi, take-off and landing the POC, if being used must be stowed under the seat in front.</li>
        </ul>

        <strong>Seat Allocation</strong>
        <p>
          Guests carrying/using the POC on board shall be assigned only a window seat, except on rows 1,12 & 13. Such Guests shall not be seated on middle or aisle seats. allianceair reserves rights to change seats of such Guests, if required.
        </p>

        <strong>Check-In of POC</strong>
        <ul>
          <li>For safety purposes, Guest must ensure that the POC prior to check in is empty and free of pressure. Thus, if the POC was used before coming to the airport, then the Guest must to reset the POC to avoid any residual oxygen in the equipment</li>
          <li>If POC operates on lithium battery, battery should be removed and carried by Guest as hand baggage. If POC operates on any other type of battery, battery should be removed and packed separately in such a way that battery terminals do not come in contact with any metal objects. Carriage of such batteries will further be subject to applicable regulatory requirements including but not limited to those mentioned under the Aircraft (Carriage of Dangerous Goods) Rules, 2003, International Civil Aviation Organization (ICAO) Technical Instructions for the Safe Transport of Dangerous Goods by Air, the International Air Transport Association (IATA) Dangerous Goods Regulations.</li>
        </ul>

        <strong>Other conditions</strong>
        <ul>
          <li>In case of the unlikely event of decompression, passengers must wear the oxygen mask from the overhead panel as a primary source of oxygen and not their POC, as the oxygen mask would ensure a continuous flow of oxygen and would also minimize the chance/ effects of decompression sickness.</li>
          <li>POC should not cause any interference with the electrical, navigation or communication equipment on board;</li>
          <li>Passenger carrying the POC on board must comply with all security requirements and instructions communicated by the Crew, from time to time;</li>
          <li>Passenger alone will be solely responsible for packing, assembling, dismantling or facilitating administration of POC both on ground and on board. allianceair will under no circumstances be liable or responsible for the same.</li>
          <li>Notwithstanding anything contained herein, allianceair, in its sole discretion, reserves the right to refuse carriage of the POC, if it is likely to endanger the safety of the aircraft or of any person</li>
        </ul>
      </CardHolder>

      <CardHolder
        id="carryDevices"
        eventKey="carryDevices"
        selectedEventKey={eventKey}
        header="Can I carry crutches, braces and or other prosthetic devices on-board?"
      >
        <p>
          Guests are allowed to use their own crutches, braces and / or other prosthetic devices up to the aircraft, and may board with such items as hand baggage, provided the Guests are dependent on them.
        </p>
      </CardHolder>

      <CardHolder
        id="differentlyAbled"
        eventKey="differentlyAbled"
        selectedEventKey={eventKey}
        header="Can seats be assigned to passengers who are differently abled?"
      >
        <p>
          We will do our best to allocate you a seat that is most suitable to your needs including movable armrest for easy accessibility to the seats However, in order to meet the safety requirements of the DGCA, passengers with mobility issues which make it unsafe to facilitate aircraft evacuations will not be given a seat in a location where they would obstruct emergency exits, impede the crew in their duties, obstruct access to emergency equipment or hinder aircraft evacuation in case of an emergency. To allow us to provide the best assistance, please provide us with as much information as possible at the time of your booking and or at the time of check-in
        </p>
      </CardHolder>

      <CardHolder
        id="differentlyAbledAssistance"
        eventKey="differentlyAbledAssistance"
        selectedEventKey={eventKey}
        header="Are differently abled passengers offered assistance by crew to use lavatory?"
      >
        <p>A lavatory with a single panel door and additional grab bar is available on all our aircraft. Our cabin crew can provide assistance moving to and from the lavatory door(same as above), but will not be able to assist the guest inside the lavatory.</p>
      </CardHolder>
      {/* </Accordion> */}
    </div>
  );
}
