import React, { useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';
import './App.scss';
import Header from './components/Header';
import Links from './components/Links';
import Main from './components/Main';
import { SQIVA_URL } from './config/config';
import { getSession, setSession } from './config/storage';
import loadIframeResizerContentWindow from './hooks/getCustomScript';

function App() {
  /*
   * 13/04/2020
   * changed code on App.jsx & App.scss
   */

  /* using postMessage to comunicate with different domain, (cross origin policy problem handle) */
  const [isLoading, setLoading] = useState(0);
  const getMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  const event = window[getMethod];
  const getMessageEvent = getMethod === 'attachEvent' ? 'onmessage' : 'message';

  if (!getSession('cookie-notify')) {
    setSession('cookie-notify', 'true');
  }

  // chrome bug path for handling faq redirects
  useEffect(() => {
    if (document.location.hash === '#some-anchor') {
      setTimeout(() => {
        document
          .querySelector('#some-anchor')
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }
  }, []);

  // required for iframe header footer
  loadIframeResizerContentWindow('./libs/js/iframeResizer.contentWindow.min.js');

  /* auto trigger loading */
  event(getMessageEvent, (e) => {
    if (e.data === 'triggerLoadingScreen' || e.message === 'triggerLoadingScreen') {
      setTimeout(() => {
        setLoading(isLoading + 1);
      }, 300);
    }
  });

  const { location } = useContext(__RouterContext);

  const iframeMode = location.pathname.toLowerCase().includes('headerframe') || location.pathname.toLowerCase().includes('footerframe');

  /* loading overlay when searching schedule */
  if (isLoading === '1' || isLoading === 1) {
    return (
      <div className="loading-screen">
          <div className="text-center position-relative">
            <img alt="sabar..." className="inner-loading" src={`${SQIVA_URL}/images/plane.png`} style={{ height: '75px' }} id="planeAnimation" />
            <h2 className="loading-text" style={{ fontWeight: 'bolder' }}>Ready when you are...</h2>
          </div>
      </div>
    );
  }

  return (
    <div className="allianceairContainer" style={{ fontWeight: 400 }}>
      { !iframeMode && <Header />}
      <Main />
      { !iframeMode && <Links />}
    </div>
  );
}

export default App;
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
