/* eslint-disable no-param-reassign */
import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { PLONE_URL } from '../config/config';
import { LanguageContext } from '../context/LanguageContext';

const ploneAPI = Axios.create({
  baseURL: `${PLONE_URL}`,
  headers: {
    // Authorization: `Basic ${encodedAuth}`,
    Accept: 'application/json',
  },
});

const getNavigationTree = async (URL) => {
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  let response = [];
  if (resp.data && resp.data.items && resp.data.items.length > 0) {
    response = resp.data.items.filter((x) => x.title !== 'Home').map((x, index) => {
      const subMenu = x.items.map((y, idx) => ({ id: idx, title: y.title, path: y['@id'].split('/').pop() }));
      return {
        id: index, title: x.title, path: x['@id'].split('/').pop(), items: subMenu,
      };
    });
  }

  return response;
};

const getHeader = () => {
  const [language] = useContext(LanguageContext);
  const navigationTree = `/allianceair/${language}/@navigation?expand.navigation.depth=2`;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!navigationTree) return;
    const fun = async () => {
      let response = [];
      setIsLoading(true);
      try {
        response = await getNavigationTree(navigationTree);
      } catch (e) {
        console.log(e);
      }
      setData(response);
      setIsLoading(false);
    };
    fun();
  }, [navigationTree]);

  return { data, isLoading };
};

export default getHeader;
