// import './fonts/Avenir.ttc';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import App from './App';

import { LanguageProvider } from './context/LanguageContext';

ReactDOM.render(<Router>
  <LanguageProvider>
  <App />
  </LanguageProvider>
                </Router>, document.getElementById('root'));
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
