/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import {
  Accordion, Card, useAccordionToggle,
} from 'react-bootstrap';
import './CardHolder.scss';
import { FaCaretRight as Right, FaCaretDown as Down } from 'react-icons/fa';
import AccordionContext from 'react-bootstrap/AccordionContext';

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      className="accordionButton"
      type="button"
      onClick={decoratedOnClick}
    >
      <h4 style={{ textAlign: 'left' }}>
        {children}
      </h4>

      {
      isCurrentEventKey ? (<Down />) : (<Right />)
      }

    </button>
  );
}

export default
function CardHolder({
  header, eventKey, children, selectedEventKey,
}) {
  return (
    <Accordion defaultActiveKey={`${selectedEventKey || '0'}`}>
      <Card id={`${eventKey}`}>
        <Card.Header>
          <ContextAwareToggle eventKey={eventKey}>
            <strong>{header}</strong>
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body style={{ marginTop: '15px' }}>
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
