/* eslint-disable max-len */
import React from 'react';
import { SQIVA_URL } from '../../../config/config';
import CardHolder from '../../../components/CardHolder';

import './FAQItem.scss';

export default function CheckIn({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      <CardHolder
        id="checkin"
        eventKey="checkin"
        selectedEventKey={eventKey}
        header="How do I check-in at the airport?"
      >
        <p>
          Passengers can check-in at allianceair counters located at airports. Passengers must keep in mind the following rules related to airport check-ins:
        </p>

        <p>
          <strong>Domestic Travel</strong>
          <ul>
            <li>
              Check-in counters closes 60 minutes prior to scheduled departure time.
            </li>
            <li>
              Boarding gates close 20 minutes prior to departure at all airports. Passengers must be present at the boarding gate no later than the time specified by allianceair when they check in or any subsequent announcements made at the airport.
            </li>
          </ul>
        </p>

        <p>
          allianceair recommends that Guests report for Check-in at least 2 hours prior to the departure of the scheduled flight. Passenger journey will be smoother if they allow themselves ample time to comply with the Check-in timelines. allianceair reserves the right to cancel a Guest’s reservation if he/she does not comply with the check-in timelines.
        </p>
      </CardHolder>
      <CardHolder
        id="webcheckin"
        eventKey="webcheckin"
        selectedEventKey={eventKey}
        header="What is Web Check-in?"
      >

        <p>
          Avoid long queues at the airport and travel hassle-free with Web Check-in. Passengers flying domestic sectors can Web Check-in at any time up to 1 hour prior to the scheduled departure time.
        </p>

        <p>
          <strong>Useful Tips:</strong>
          <ul>
            <li>
              Please don’t forget to carry a printout of your boarding card.
            </li>
            <li>
              If you're carrying check-in baggage, please go straight to our bag drop counter.
            </li>
            <li>
              Hand baggage weight should not be more than 5 kgs.
            </li>
            <li>
              Free check-in baggage allowance is 15 kgs. In case you wish to pre purchase excess baggage, please click here.
            </li>
            <li>
              Departure gate closes 20 minutes prior to the scheduled departure time.
            </li>
            <li>
              Please make sure you clear all your pending payments in order to Web Check-in for your upcoming flight.
            </li>

          </ul>
        </p>

        <p>
          You are not eligible for Web Check-in service –
          <ol>
            <li>
              If you have a reservation for any Special Service besides meals, for example, Wheelchair, Stretcher, Medical Passenger, Guide Dog or Fire Arms.
            </li>
            <li>
              If you have made a booking under Armed Forces Personnel, Senior Citizen, Unaccompanied Minor, Student Fares or Group Booking (more than 9 passengers on a single reservation number).
            </li>
          </ol>
        </p>

      </CardHolder>

      <CardHolder
        id="kioskcheckin"
        eventKey="kioskcheckin"
        selectedEventKey={eventKey}
        header="What is Kiosk Check-in?"
      >
        <p>
          Kiosk Check-in is a convenient way  for passengers to check-in using allianceair kiosks located at the airport. Passengers can select their preferred seat and print their boarding pass.
        </p>

        <strong>Registration</strong>
        <p>
          Passengers DO NOT need to register to avail of our Kiosk Check-in facility.
        </p>

        <strong>
          Late Arrival
        </strong>
        <p>
          Passengers will not be able to print out their boarding pass from the kiosk in case they arrive late and the check-in counters for the flight are closed.
        </p>
      </CardHolder>
      {/* </Accordion> */}

    </div>

  );
}
