/* eslint-disable import/order */

import './Book.scss';

import * as Yup from 'yup';

import {
  Carousel,
  Col,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import {
  CitySelector,
  CountSelector,
} from './Select';
import { GoChevronLeft as FaChevronLeft, GoChevronRight as FaChevronRight } from 'react-icons/go';
import { Formik, Form as FormikForm, useField } from 'formik';
import {
  PLONE_PASSWORD,
  PLONE_URL,
  PLONE_USER,
  SQIVA_URL,
} from '../../config/config';

import { BANNERS } from '../../constants';
import { FaExclamationCircle } from 'react-icons/fa';
import FadeIn from '../../components/Animations/Animator';
import { IconContext } from 'react-icons';
import Iframe from 'react-iframe';
import React from 'react';
// import { SQIVA_URL } from '../../config/config';
import Marquee from '../../components/Marquee';
import getPloneContent from '../../hooks/getContent';
import MultiCarousel from '../../components/MultiCarousel';
// import ReadMoreLess from '../../read-more-less';

function CitySelectorFormik({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <>
      <Form.Group
        as={Row}
        noGutters
        style={{
          alignItems: 'center',
          background: '#e2e5e8',
          padding: '0 5px',
          border: `${meta.touched && meta.error ? '1px solid #B62079' : 'none'
          }`,
          position: 'static',
          borderRadius: '3px',
        }}
      >
        <Form.Label column xs="auto">
          {meta.touched && meta.error ? (
            <FaExclamationCircle />
          ) : null}
          {label}
        </Form.Label>
        <Col style={{ position: 'static' }}>
          <CitySelector
            value={value}
            onChange={setValue}
            {...props}
          />
        </Col>
      </Form.Group>
    </>
  );
}

function PersonSelectorFormik({ label, ...props }) {
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <div style={{ position: 'relative' }}>
      <p>hiiiiiiiii</p>
      <Form.Group
        as={Row}
        noGutters
        style={{
          alignItems: 'center',
          background: '#e2e5e8',
          padding: '0 5px',
          borderRadius: '3px',
        }}
      >
        <Form.Label column xs="auto">
          {label}
        </Form.Label>
        <Col style={{ position: 'static' }}>
          <CountSelector
            value={value}
            onChange={setValue}
            {...props}
          />
        </Col>
      </Form.Group>
    </div>
  );
}

export default function Book() {
  const { data, isLoading } = getPloneContent('media-content/hero-sliders', 'images');
  return (
    <FadeIn>
      <IconContext.Provider value={{ color: 'white' }}>
        <div>
          <div className="bookContainer">
            <div className="bookOverlayCarousel">
              <IconContext.Provider value={{ size: '4em', color: 'white', boxShadow: '5px 10px gray' }}>
                <Carousel
                  style={{ margin: '0px', height: '600px' }}
                  nextIcon={
                    <FaChevronRight />
                  }
                  prevIcon={
                    <FaChevronLeft />
                  }
                  indicators={false}
                >
                  {
                    data.map((banner) => (
                      <Carousel.Item key={banner.id} style={{ height: 'auto', backgroundSize: 'cover' }}>
                        { banner.url
                    && (
                    <a href={banner.url} target="_blank ">
                      <img
                        className=" w-100"
                        src={banner.image}
                        alt="First slide"
                        style={{ height: '600px', backgroundSize: 'cover' }}
                      />
                    </a>
                    )}
                        {
                      !banner.url
                      && (
                      <img
                        className=" w-100"
                        src={banner.image}
                        alt="First slide"
                        style={{ height: '600px', backgroundSize: 'cover' }}
                      />
                      )
                    }
                      </Carousel.Item>
                    ))

                  }
                </Carousel>
              </IconContext.Provider>
            </div>
            <div className="bookOverlayControlContainer">
              <div className="bookOverlayControls">
                <Col className="override" xs={{ span: 12 }} style={{ padding: 0 }}>
                  <Formik
                    initialValues={{
                      origin: null,
                      destination: null,
                      adult: 1,
                      child: 0,
                      infant: 0,
                      startDate: null,
                      returnDate: null,
                      bookingType: 'Round trip',
                      actionType: 'book',
                    }}
                    onSubmit={(values, actions) => {
                      // POST action goes here
                      setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                      }, 1000);
                    }}
                    validationSchema={
                      Yup.object().shape({
                        origin: Yup.object()
                          .required('Required'),
                        destination: Yup.object()
                          .required('Required'),
                        startDate: Yup.string()
                          .required('Required'),
                        returnDate: Yup.mixed().when('bookingType', {
                          is: 'Round trip',
                          then: Yup.string().required('Required'),
                        }),
                      })
                    }
                  >
                    {(props) => (
                      <FormikForm className="bookForm">
                        <Iframe
                          url={`${SQIVA_URL}/search-schedule/iframe`}
                          className="bookframe"
                          frameBorder="0"
                          z-index="50"
                          overflow-y="auto"
                          overflow-x="auto"
                          resize="both"
                        />
                      </FormikForm>
                    )}
                  </Formik>
                </Col>
              </div>
            </div>
          </div>
          <div className="bookContainer">
            <div style={{ paddingTop: '10px' }}>
              <Marquee type="below-booking-widget" />
            </div>
            <MultiCarousel type="booking-carousel" />
            <div style={{ paddingTop: '10px' }}>
              <Marquee type="multi-carousel" />
            </div>
            <MultiCarousel type="events-carousel" />
          </div>
        </div>
      </IconContext.Provider>
    </FadeIn>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
