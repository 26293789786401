import React from 'react';
import './FAQItem.scss';
import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'BaggageAllowance',
    header: 'How much baggage am I allowed to carry?',
    body: (
      <>
        <table className="allianceairTable" style={{ width: 'auto' }}>
          <tr>
            <td colSpan={4}>
              <strong>Free Baggage Allowance</strong>
            </td>
          </tr>

          <tr>
            <th />
            <th>Hand Baggage</th>
            <th colSpan={2}>Check-in Baggage</th>
          </tr>

          <tr>
            <td>Maximum Weight</td>
            <td>Only one bag weighing not more than 7 kgs.</td>
            <td>Domestic flights</td>
            <td>15kg</td>
          </tr>

          <tr>
            <td>Dimensions</td>
            <td>55cm x 35cm x 25cm</td>
            <td colSpan={2}>
              <p>158cm (62 inches)</p>
              <p>(Length + Width + Height)</p>
            </td>
          </tr>
        </table>

        <p>
          In addition to the one piece of Hand Baggage permitted to be carried in accordance with the above,  allianceair will permit a Guest to carry one additional personal article such as ladies’ purse or a small bag containing laptop not weighing more than 3 kgs.
        </p>
        <p>Items determined by us to be of an offensive nature, will not be permitted on board.</p>
        <p>Subject to the prevalent applicable local laws and regulations, Guests may carry liquids in their Hand Baggage, subject to screening and security checks, and provided they meet the following restrictions:</p>
        <ul>
          <li>Any liquid is in a container with a maximum volume of 100ml; and</li>
          <li>All liquid containers meeting the maximum volume of 100ml each can be fitted comfortably into a transparent, re-sealable 1 litre plastic bag.</li>
        </ul>
        <p>
          If a Guest wishes to carry an oversized item on board which is not compliant with the permissible limits set forth above, but will fit safely in a seat, allianceair may allow such Guest at its discretion to purchase an additional seat on that flight, subject to availability and applicable fares. This facility is not available as part of online reservation and Guest may call our guest care number to arrange for booking such a seat for your Baggage. allianceair reserves the right to refuse to carry any items or Baggage, due to excessive size or otherwise, if allianceair deems it to be a safety risk.
        </p>
        <strong>Infant Baggage Allowance</strong>
        <p>
          Domestic Travel:
        </p>
        <ul>
          <li>Hand Baggage-Up to 07 kgs</li>
          <li>Check In Baggage -NIL</li>
        </ul>
        <p>
          One stroller or baby pram per infant is allowed without any charge
        </p>
      </>
    ),
  },
  {
    id: 'BaggagePrepaid',
    header: 'What is the pre-paid excess baggage slab and how can I book it?',
    body: (
      <>
        <p>
          Passengers can book pre-paid excess baggage up to 2 hours prior to the scheduled departure of their flight via our website or call centre.
        </p>
        <strong>Domestic</strong>
        <p>
          To facilitate affordable purchase of excess baggage, the following pre-paid excess baggage slabs are offered to Domestic travelers:
        </p>
        <table className="allianceairTable" style={{ width: 'auto' }}>
          <tr>
            <th>Baggage</th>
            <th>1kg – 4kg</th>
            <th>5 kg – 9kg</th>
            <th>10 Kg +</th>
            <th>At Airport Per Kg</th>
          </tr>

          <tr>
            <td>INR</td>
            <td>999/-</td>
            <td>1999/-</td>
            <td>2999/-</td>
            <td>500</td>
          </tr>

          <tr>
            <td colSpan={5}>
              <i>Please note that if the payment is to be made in any other currency than Indian Rupee, the fee(s) will be calculated as per the conversion rate prevalent at the time of payment.</i>
            </td>
          </tr>
        </table>
      </>
    ),
  },
  {
    id: 'BaggageExcess',
    header: 'What if I exceed my free baggage weight allowance?',
    body: (
      <>
        <p>
          Guests carrying more than the allowed baggage limit will be charged the following excess baggage fees at the time of check-in:
        </p>
        <table className="allianceairTable" style={{ width: 'auto' }}>
          <tr>
            <th>
              Domestic Travel
            </th>
          </tr>
          <tr>
            <td>
              <p>INR 500</p>
              <p>per additional kg</p>
            </td>
          </tr>
        </table>
        <p>
          Pre-Paid Excess Baggage: Passengers can book pre-paid excess baggage up to 2 hours prior to the scheduled departure of their domestic flight via our website or call centre.
        </p>
      </>
    ),
  },
  {
    id: 'BaggageProhibited',
    header: 'What items are not permitted on allianceair flight?',
    body: (
      <>
        <p>
          <strong>Prohibited in Checked and Cabin baggage:</strong>
        </p>
        <ul>
          <li>Compressed gases - deeply refrigerated, flammable, non-flammable and poisonous such as butane oxygen, liquid nitrogen, aqualung cylinders and compressed gas cylinders</li>
          <li>Corrosives such as acids, alkalis, mercury and wet cell batteries and apparatus containing mercury</li>
          <li>Explosives, munitions, fireworks and flares, ammunition including blank cartridges, handguns, fire- works, pistol caps</li>
          <li>Flammable liquids and solids such as lighter refills, lighter fuel, matches, paints, thinners, fire-lighters, lighters that need inverting before ignition, matches (these may be carried on the person), radioactive material, briefcases and attached case with installed alarm devices</li>
          <li>Oxidizing materials such as bleaching powder and peroxides</li>
          <li>Poisons and infectious substances such as insecticides, weed-killers and live virus materials</li>
          <li>Fish (including other sea food), animals, birds, insects in any form, whether alive and/or dead, and/or frozen and/or dried and/or cooked</li>
          <li>Anything that possesses and/or is capable of possessing and/or emitting a conspicuous and/or offensive odour</li>
          <li>Other dangerous articles such as magnetized materials, offensive or irritating materials</li>

        </ul>
        <p><strong>Prohibited items in Cabin Baggage:</strong></p>
        <ul>
          <li>Dry cell batteries</li>
          <li>Knives, scissors, Swiss army knives and other sharp instruments</li>
          <li>Toy replicas  of fire arms and ammunition</li>
          <li>Weapons such as whips, nan-chakus, baton, or stun gun</li>
          <li>Electronic devices which cannot be switched off</li>
          <li>Aerosols and liquids*</li>
          <li>Any other items which are deemed security hazards by local law</li>
        </ul>
        <p>*Passengers can  carry one clear transparent re-sealable litre sized plastic bag containing small quantities of liquid items / gels / pastes/aerosols or items of a similar consistency. Liquids / gels / aerosols include but are not limited to beverages, shampoos, suntan lotions, creams, toothpastes, hair gels and hair sprays. The contents will be subject to screening and security checks and must comply with allianceair's security guidelines about permissible objects.</p>
        <p />
        <p><strong>Please note:</strong></p>
        <p>allianceair recommends that all medication, valuables, fragile items, perishable items and precious items (cameras, jewellery, money, electronics, etc.)  should be carried in Cabin Baggage and not in Checked Baggage. allianceair shall not accept any responsibility for these items.</p>

      </>
    ),
  },
  {
    id: 'BaggageAdvisories',
    header: 'Are there any general advisories that I should keep in mind regarding my baggage?',
    body: (
      <>
        <p>
          Yes, there are many:
        </p>
        <ul>
          <li>Don't accept any packets from unknown persons</li>
          <li>Don't leave baggage unobserved at any time, especially at the airport. Unattended baggage may be removed by Airport Security as an object of suspicion</li>
          <li>Please declare if you are carrying any arms or explosive substances, prior to Baggage screening/ check-in .</li>

        </ul>
        <p><strong>Concealment is considered an offence under the Aircraft Act and Rules</strong></p>
        <ul>
          <li>Clearly label all baggage items with passenger's name, address and contact details</li>
          <li>All prohibited/restricted items are liable to be removed by security and allianceair may not be in a position to return such removed items</li>
        </ul>

      </>
    ),
  },
  {
    id: 'BaggageAlcohol',
    header: 'Carriage of Alcoholic beverages?',
    body: (
      <>
        <p>
          Passengers may carry upto 5 Liters of alcoholic beverages as part of their checked-in baggage, provided the following conditions are met:
        </p>
        <ol>
          <li>The alcoholic beverage is in retail packaging and is packed appropriately (to prevent damage / leakage).</li>
          <li>Alcohol content in the beverage is not more than 70%.</li>
          <li>Alcohol content in the beverage is more than 70% should not be accepted.( Because that's pure spirit comes under DG hence cannot be transported)</li>
        </ol>

        <p>If the alcoholic beverage contains 24% or less alcohol by volume the above limitation of 5 Liters does not apply.</p>
        <p>The alcoholic beverages is also permitted in carry-on baggage when purchased from the Airport Security Hold Area and should be placed in a transparent re-sealable plastic bag of a maximum capacity not exceeding 1 Liter. The indicative size of the 1 Liter bag is: 20.5 cm x 20.5 cm or 25 cm x 15 cm or equivalent. The containers must fit comfortably within the bag, which should be fully closed.</p>
        <p>The passengers must comply with other applicable state / national regulations, if any.</p>
        <p>Carrying liquor to/from airports in Gujarat & Bihar states is strictly prohibited as Gujarat / Bihar are dry states.</p>

      </>
    ),
  },
  {
    id: 'BaggageOverSize',
    header: 'How can I carry non-standard baggage such as an odd-sized item or an item that provides special assistance?',
    body: (
      <>
        <strong>Over Sized / Odd-Sized Items</strong>
        <p>
          If you wish to carry an oversized item on board, which will fit safely in a seat, you must purchase an additional seat on that flight, subject to availability and applicable fares. However, allianceair reserves the right to refuse carrying of any large item(s) which we feel may present a safety risk.
        </p>
        <strong>Special Assistance Items</strong>
        <p>
          A Passenger requiring a fully collapsible wheelchair and / or a pair of crutches and / or braces or other prosthetic device may board such items as Cabin Baggage, provided that the passenger is dependent upon them. In case of fragile items allianceair has limited liability.
        </p>

      </>
    ),
  },
  {
    id: 'BaggageSpecial',
    header: 'What items are considered as special baggage and how can I carry them?',
    body: (
      <>
        <p>
          The following items are considered as Special Baggage:
        </p>
        <ol>
          <li>Sports/Skiing equipment</li>
          <li>Golf bags</li>
          <li>Bicycles</li>
          <li>Surf boards</li>
          <li>LCD and LED TVs of size more than 99.06 cms (size up to 81.28 cm for ex-Riyadh flights)</li>
          <li>Other large and/or odd-sized items, including cartons, whose dimensions (L+W+H) exceed 158 cms</li>
        </ol>
        <p>allianceair will levy a fee of INR 1500 (or equivalent in foreign currency) for Domestic flights for any Special Baggage that is submitted as Checked Baggage. This fee will be applied over and above any Excess Baggage charges that might be applicable.</p>
        <p><strong>Please note:</strong></p>
        <p>Jewellery: allianceair does not hold any liability on carrying cash or jewellery but it is subject to clearance by the airport regulatory authorities.</p>
        <p>Musical Instruments: Carriage of musical instruments is allowed in our Domestic at no additional cost. Guitars, if packed in soft cases, can be carried in hand baggage, however, other instruments have to be properly packed and can be carried in Check-in baggage only.</p>

      </>
    ),
  },
  {
    id: 'BaggageLost',
    header: 'I have lost my handbag, what do I do?',
    body: (
      <>
        <p>
          Guests are solely responsible for carriage of their hand bag / personal belongings. allianceair is not liable for any loss / damage caused to guest's hand baggage / personal belongings.
        </p>
      </>
    ),
  },
  {
    id: 'BaggageLossNoReport',
    header: 'What happens if I am unable to report loss / damage to my bag?',
    body: (
      <>
        <p>
          Any receipt of baggage without complaint on the termination of the journey shall be prima facie evidence that the baggage has been delivered correctly and in good condition. A guest who leaves the baggage delivery area without reporting a bag as missing or damaged to an allianceair representative will be deemed to have received his/her bag correctly and in good condition.
        </p>
      </>
    ),
  },
];

export default function Baggage({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              selectedEventKey={eventKey}
              id={item.id}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
