import './Navbar.scss';
import React, { useState, useEffect, useContext } from 'react';
import {
  NavLink,
  BrowserRouter as Router,
  Link,
} from 'react-router-dom';
import {
  Row, Button, Col, Image, Navbar, Nav, a, Dropdown, Accordion, Card,
} from 'react-bootstrap';
import {
  FaBars, FaTimes, FaHome, FaChevronDown as FaArrowDown,
} from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { HOME_URL, SQIVA_URL } from '../config/config';
import { useGetMedia, SQIVA_URLS } from '../constants';
import getHeader from '../hooks/getHeader';

export default function AllianceAirNavbar({ props }) {
  const media = useGetMedia();
  const [active, setActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const { data, isLoading } = getHeader();
  useEffect(() => {
    if (media >= 2) {
      setActive(false);
    }
  }, [media]);

  const HeaderList = data.map((menu) => {
    const response = (
    <li key={menu.id}>
      <Dropdown
        onMouseEnter={() => setActiveMenu(menu.title)}
        onMouseLeave={() => setActiveMenu('')}
        onClick={() => setActiveMenu(menu.title)}
        show={activeMenu === menu.title}
        drop="down"
      >
        <Dropdown.Toggle style={{ fontSize: 'large', fontWeight: 'bold' }} type="Link">
          {menu.title}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {menu.items.map((submenu) => {
            const submenuItem = (
              <Dropdown.Item key={submenu.id} target="_parent" href={SQIVA_URLS.includes(submenu.path) ? `${SQIVA_URL}/${submenu.path}` : `/${submenu.path}`}>{submenu.title}</Dropdown.Item>
            );
            return submenuItem;
          })}
        </Dropdown.Menu>
      </Dropdown>
    </li>
    );
    return response;
  });

  const HeaderAccordion = data.map((menu) => {
    const response = (
    <li key={menu.id}>
      <Dropdown
        onMouseEnter={() => setActiveMenu(menu.title)}
        onMouseLeave={() => setActiveMenu('')}
        onClick={() => setActiveMenu(menu.title)}
        show={activeMenu === menu.title}
        drop="auto"
      >
        <Dropdown.Toggle style={{ fontWeight: 'bold' }} type="Link">
          {menu.title}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {menu.items.map((submenu) => {
            const submenuItem = (
              <Dropdown.Item key={submenu.id} target="_parent" href={SQIVA_URLS.includes(submenu.path) ? `${SQIVA_URL}/${submenu.path}` : `/${submenu.path}`}>{submenu.title}</Dropdown.Item>
            );
            return submenuItem;
          })}
        </Dropdown.Menu>
      </Dropdown>
    </li>
    );
    return response;
  });

  const ServicesAccordion = (
    <Dropdown
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      onClick={() => setShowDropdown(true)}
      show={showDropdown}
      drop="auto"
    >
      <Dropdown.Toggle style={{ fontWeight: 'normal' }} type="Link">
        Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Manage Booking</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/web-checkin`}>Web Check-in</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Travel Certificate</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const servicesDropdown = (
    <Dropdown
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      show={showDropdown}
      drop="down"
    >
      <Dropdown.Toggle style={{ fontSize: 'large', fontWeight: 'normal' }} type="button">
        Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Manage Booking</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/web-checkin`}>Web Check-in</Dropdown.Item>
        <Dropdown.Item target="_parent" href={`${SQIVA_URL}/manage-booking`}>Travel Certificate</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <div className="allianceairNavbar">
      {media >= 2 && (
        <Row sm={0} md={12} className="horizontalNavbar">
          <ul>
            <li>
              <a target="_parent" href={`${HOME_URL}/book`}>
                <FaHome fontSize="1.5em" />
              </a>
            </li>
            {HeaderList}
          </ul>
        </Row>
      )}
      {media < 2 && (
        <Row className="allianceairVerticalNavbar">
          <IconContext.Provider value={{ color: 'white', size: '20px', backgroundColor: 'transparent' }}>
            <FaBars
              onClick={function () {
                setActive(true);
                window.parent.postMessage('openDropdown', '*');
              }}
              className={active ? 'm-fadeOut' : 'm-fadeIn'}
            />
            <FaTimes
              onClick={function () {
                setActive(false);
                window.parent.postMessage('closeDropdown', '*');
              }}
              className={!active ? 'm-fadeOut' : 'm-fadeIn'}
            />
            <div
              className={`allianceairNavBarDropDown ${active ? 'allianceairNavBarDropDownactive' : 'allianceairNavBarDropDowninactive'}`}
            >
              <ul className={`${active ? '' : 'hidden'}`}>
                <li>
                  <a target="_parent" href={`${HOME_URL}/book`}>
                    <FaHome color="#c00000" />
                  </a>
                </li>
                {HeaderAccordion}
              </ul>
            </div>
          </IconContext.Provider>
        </Row>
      )}
    </div>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
