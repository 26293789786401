/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import './ReadMore.scss';

const ELLIPSES = '…';
const SHOW_LESS_TEXT = 'Show Less';
const SHOW_MORE_TEXT = 'Read More';

function isEllipsisActive(e) {
  if (e) {
    return (e.offsetWidth < e.scrollWidth);
  }

  return false;
}

export default function ReadMore({ links }) {
  const [readMoreActive, setReadMoreActive] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const textDiv = useRef(null);

  useEffect(() => {
    function handleResize() {
      console.log('🚀 ~ file: ReadMore.jsx ~ line 19 ~ useEffect ~ textDiv', isEllipsisActive(textDiv.current));
      if (textDiv.current && isEllipsisActive(textDiv.current)) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <div className="read-more-line">
        <div ref={textDiv} className={`${readMoreActive ? 'read-more' : 'read-less'} read-more-text`}>
          {links}
        </div>
        {showReadMore && (
<div>
        <button type="button" className="clear-button" onClick={() => setReadMoreActive(!readMoreActive)}>
          { readMoreActive ? 'Read Less' : 'Read More'}
{' '}
{ readMoreActive ? <FaArrowUp /> : <FaArrowDown /> }
        </button>
</div>
        )}
    </div>
  );
}
