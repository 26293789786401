/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import {
  Row, Col, Form, Button, Image,
} from 'react-bootstrap';
import { Markup } from 'interweave';
import Carousel from 'react-multi-carousel';
import './MultiCarousel.scss';
import getPloneContent from '../hooks/getContent';
import 'react-multi-carousel/lib/styles.css';

const CustomLeftArrow = ({ onClick }) => (
  <i onClick={() => onClick()} className="custom-left-arrow" />
);
const CustomRightArrow = ({ onClick }) => <i className="custom-right-arrow" onClick={() => onClick()} />;

export default function MultiCarousel(props) {
  const { data, isLoading } = getPloneContent(
    `media-content/${props.type}`,
    'campaign',
  );
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };
  // { prop.type === 'event-carousel' ? responsive.desktop.items = 3 : responsive.desktop.items = 4; }
  { props.type === 'booking-carousel' ? responsive.desktop.items = 4 : responsive.desktop.items = 3; }
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      // customButtonGroup={<CustomButtonGroupAsArrows />}
      autoPlay={false}
      autoPlaySpeed={1000}
      centerMode={false}
      className="mc-container"
      containerClass="container-with-dots"
      dotListClass="mc-dotlist"
      draggable
      focusOnSelect={false}
      infinite
      itemClass="mc-item"
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside
      renderDotsOutside={false}
      responsive={responsive}
      showDots={false}
      sliderClass="mc-slider"
      slidesToSlide={1}
      swipeable
    >
        {data.map((item) => (
          <div key={`mc-${item.id}`} className="containerme">
             {
              item.text && item.url && (
                <>
                <a href={item.url} target="_blank ">
                <button type="button" className="mybtn">{item.text}</button>
                </a>
                <img alt="a" src={item.image} className="imgme" />
                </>
              )
            }
            {
              !item.text && item.url && (
                <a href={item.url} target="_blank ">
                  <img alt="a" src={item.image} />
                </a>
              )
            }
            {
              !item.text && !item.url && (
                <img alt="a" src={item.image} />
              )
            }
          </div>
        ))}
    </Carousel>
  );
}
