/* eslint-disable import/order */
/* eslint-disable no-param-reassign */

import { PLONE_URL } from '../config/config';
import React, { useEffect, useState } from 'react';

import Axios from 'axios';

const ITEM_TYPE = {
  Folder: 'Folder',
  Document: 'Document',
  Image: 'Image',
  Campaign: 'campaign',
};

const ploneAPI = Axios.create({
  baseURL: `${PLONE_URL}`,
  headers: {
    Accept: 'application/json',
  },
});

const getDocumentData = async (item) => {
  const resp = await ploneAPI.get(item['@id'].replace(PLONE_URL, ''));
  return { title: resp.data.title, text: resp.data.text };
};

const getImageData = async (item) => {
  console.log(item);
  const resp = await ploneAPI.get(item['@id'].replace(PLONE_URL, ''));
  console.log(resp);
  return { title: resp.data.title, text: resp.data.text };
};

const getCampaignData = async (item) => {
  console.log(item);
  const resp = await ploneAPI.get(item['@id'].replace(PLONE_URL, ''));
  console.log(resp);
  return {
    title: resp.data.title,
    text: resp.data.text,
    image: resp.data.image.download,
    buttontext: resp.data.button_text,
    link: resp.data.link,
  };
};

const getFolderData = async (URL, root) => {
  if (!root) {
    root = {};
  }
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  await Promise.all((resp.data.items || []).map(async (pItem) => {
    if (pItem['@type'] === ITEM_TYPE.Folder) {
      root[pItem.title] = await getFolderData(pItem['@id'], root[pItem.title]);
    }
    if (pItem['@type'] === ITEM_TYPE.Document) {
      root[pItem.title] = await getDocumentData(pItem);
    }
    if (pItem['@type'] === ITEM_TYPE.Image) {
      root[pItem.title] = await getImageData(pItem);
    }
    if (pItem['@type'] === ITEM_TYPE.Campaign) {
      root[pItem.title] = await getCampaignData(pItem);
    }
  }));
  return root;
};

const usePlone = (URL, dependencies) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!URL) return;
    const fun = async () => {
      let response = null;
      setIsLoading(true);
      try {
        response = await getFolderData(URL);
      } catch (e) {
        response = e;
      }
      console.log({ response });
      setData(response);
      setIsLoading(false);
    };
    fun();
  }, [URL]);

  return { data, isLoading };
};

export default usePlone;
