/* eslint-disable jsx-a11y/anchor-is-valid */
import './Links.scss';
import React, { useContext } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Copyright from './Copyright';
import {
  HOME_URL, SQIVA_URL, FACEBOOK_URL, TWITTER_URL, INSTAGRAM_URL, LINKEDIN_URL,
} from '../config/config';
import useGetFooter from '../hooks/getFooter';
import { FOOTER_SECTIONS } from '../constants';
import ReadMore from './ReadMore';
import { LanguageContext } from '../context/LanguageContext';

export default function Links() {
  const { data, isLoading } = useGetFooter();
  const location = useLocation();
  const [language, setLanguage] = useContext(LanguageContext);
  const footerLinks = data && data[FOOTER_SECTIONS.FOOTER_LINKS] && data[FOOTER_SECTIONS.FOOTER_LINKS].map((item, index) => (
      <Col key={item.id} lg={2} md={2} sm={12} className="linkSection">
        <br />
        <p className="linksTitle">{item.title}</p>
        {item.items.map((subItem) => (
          <div>
            <a className="displayInline" key={subItem.id} target="_parent" href={subItem.url}>{subItem.title}</a>
          </div>
        ))}
        <br />
      </Col>
  ));

  const belowFooterLinks = data && data[FOOTER_SECTIONS.BELOW_FOOTER] && data[FOOTER_SECTIONS.BELOW_FOOTER].map((item, index) => (
      <React.Fragment key={item.id}>
      <a rel="noreferrer" target="_blank" href={item.url}>
        {item.title}
      </a>
      {data[FOOTER_SECTIONS.BELOW_FOOTER].length !== index + 1 ? <span>|</span> : ''}
      </React.Fragment>
  ));

  const ourRoutesLinks = data && data[FOOTER_SECTIONS.OUR_ROUTES] && data[FOOTER_SECTIONS.OUR_ROUTES].map((item, index) => (
      <span key={item.id}>
      <a target="_parent" href={item.url}>
        {item.title}
      </a>
      {data[FOOTER_SECTIONS.OUR_ROUTES].length !== index + 1 ? <span> | </span> : ''}
      </span>
  ));

  const topDestinationsLinks = data[FOOTER_SECTIONS.TOP_DESTINATIONS] && data[FOOTER_SECTIONS.TOP_DESTINATIONS].map((item, index) => (
      <span key={item.id}>
        <a target="_parent" href={item.url}>
          {item.title}
        </a>
        {data[FOOTER_SECTIONS.TOP_DESTINATIONS].length !== index + 1 ? <span> | </span> : ''}
      </span>
  ));

  return (
    <>
      {location.pathname !== '/footerframe' && (
        <div className="above-footer">
          <div>
          { language === 'en' ? (<p>Top Destinations</p>) : (<p>शीर्ष गंतव्य</p>) }
            <ReadMore
              links={topDestinationsLinks}
            />
          </div>
          <div>
          { language === 'en' ? (<p>Our Routes</p>) : (<p>हमारे मार्ग</p>) }
            <ReadMore
              links={ourRoutesLinks}
            />
          </div>
        </div>
      )}
      <div className="footer-bg">
        <Row style={{ margin: '0px' }} className="links">
          {footerLinks}
        </Row>
        <div className="social-media">
          <img alt="Alliance Air" src="/aaallogo.png" style={{ height: '56px' }} className="logo-container" />
          <div>
  { language === 'en' ? (<p>Let's Stay Connected</p>) : (<p>आइए जुड़े रहें</p>) }
            <a rel="noreferrer" target="_blank" style={{ display: 'inline' }} href={FACEBOOK_URL}>
              <Image src={`${HOME_URL}/social/facebook.ico`} style={{ height: '30px' }} />
            </a>
            <a rel="noreferrer" target="_blank" style={{ display: 'inline', margin: '5px' }} href={TWITTER_URL}>
              <Image src={`${HOME_URL}/social/twitter.ico`} style={{ height: '30px' }} />
            </a>
            <a rel="noreferrer" target="_blank" style={{ display: 'inline' }} href={INSTAGRAM_URL}>
              <Image src={`${HOME_URL}/social/instagram.ico`} style={{ height: '30px' }} />
            </a>
            <a rel="noreferrer" target="_blank" style={{ display: 'inline', margin: '5px' }} href={LINKEDIN_URL}>
              <Image src={`${HOME_URL}/social/linkedin.ico`} style={{ height: '30px' }} />
            </a>
          </div>
        </div>
      </div>
      <div className="below-footer">
        {belowFooterLinks}
      </div>
      <Copyright />
    </>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
