/* eslint-disable max-len */
import { Markup } from 'interweave';
import React, { useContext } from 'react';
import CardHolder from '../../../components/CardHolder';
import usePlone from '../../../hooks/plone';
import './FAQItem.scss';
import { LanguageContext } from '../../../context/LanguageContext';

export default function ManageBooking({ eventKey }) {
  const [language] = useContext(LanguageContext);
  const { data, isLoading } = usePlone(`/allianceair/${language}/plan-my-trip/`, [language]);
  console.log(data);

  return (
    <div className="faqitem color-primary">
      {
        isLoading && <div> Loading... </div>
      }
      {
        !isLoading && Object.values(data || {}).map((webpage, index) => (
          <CardHolder
            className="color-primary"
            header={webpage.title}
            id={`asdfasdf-${index}`}
            selectedEventKey={index}
            eventKey={`asdfasdf-${index}`}
          >
            Sagar
          </CardHolder>
        ))
      }
    </div>
  );
}
