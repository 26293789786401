import React from 'react';
import './FAQItem.scss';
import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'FDCNotice',
    header: 'Will I be contacted in advance if my flight is delayed, preponed or cancelled?',
    body: (
      <>
        <p>If you have provided us with your SMS-capable mobile phone number while booking, then allianceair will notify you in advance through an SMS on your mobile phone. Passengers will also be sent the revised itinerary on their registered email address.</p>
        <p>We strongly recommend all passengers to provide us with their correct phone number and email address at the time of booking, in order to enable us to inform them in unforeseen cases of flight delays or cancellations.</p>

      </>
    ),
  },
  {
    id: 'FDCPolicy',
    header: 'What is allianceair\'s policy if a flight is cancelled, delayed or there is planned/unplanned schedule change?',
    body: (
      <>
        <p>
          allianceair reserves the right to cancel, reschedule or delay the commencement or continuance of a flight or to alter the stopping place or to deviate from the route of the journey or to change the type of aircraft in use without incurring any liability in damages or otherwise to the Guests or any other person whatsoever. Sometimes circumstances beyond allianceair’s control result in flight delays or cancellations. In such circumstances, allianceair reserves the right to cancel or delay a flight without prior notice. Circumstances beyond allianceair’s control can include, without limitation, weather; air traffic control; mechanical failures; acts of terrorism; acts of nature; force majeure; strikes; riots; wars; hostilities; disturbances; governmental regulations, orders, demands or requirements; shortages of critical manpower, parts or materials; labour unrest; etc. allianceair does not connect to other airlines and is not responsible for any losses incurred by Guests while trying to connect to or from other airlines. In case of delays of more than 2 hours or advancement of the flight by more than 1 hour, guests are entitled for a full refund or re-booking onto an alternative allianceair flight at no additional cost subject to availability. In the special case in which a subsequent portion of allianceair flight is cancelled while a guest is already in transit, the guest shall have the right to:
        </p>
        <ul>
          <li>Choose to remain at the transit station and accept a full refund</li>
          <li>Return to the point of origin and receive a full refund or a credit for future travel on allianceair</li>
          <li>Re-booking onto an alternative flight or mode of transport at no additional cost</li>
        </ul>
        <p>
          Any compensation arising out of cancellation or delay of flights will be processed as per DGCA guideline outlined in Civil Aviation Requirement, Section - 3, Series M Part IV.
        </p>
        <p>In case of delay or cancellation of a flight operated as part of the Connected Segments/Route scheme, the following shall apply:</p>
        <p>In case of delay or cancellation of the connecting flight at the point of origin which leads to the possibility of a missed connection at the point of transit, the passenger shall have right to choose a refund, or a credit for future travel on allianceair, or re-booking onto an alternative allianceair flight at no additional cost subject to availability.</p>
        <p>In case of a delay or cancellation of the connecting flight at the point of transit, allianceair will, at its discretion and subject to availability of seats, first offer the passenger the option to travel on the next allianceair flight, or that of another airline or if either options cannot materialize, then at its discretion, provide lodging for overnight stay to the passenger at the point of transit, ground transportation to and fro the airport and hotel and dinner.</p>
      </>
    ),
  },
  {
    id: 'FDCBenefits',
    header: 'Will allianceair provide any additional benefits to passengers in case their flight is cancelled or rescheduled?',
    body: (
      <>
        <p>
          allianceair will not be liable for any loss incurred due to flight cancellation or rescheduling, and passengers will not be provided any additional benefits
          <strong>other than those mentioned in DGCA CAR guidelines</strong>
        </p>
      </>
    ),
  },
];

export default function FlightDelayCancellations({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              selectedEventKey={eventKey}
              id={item.id}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
