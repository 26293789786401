/* eslint-disable no-param-reassign */
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { PLONE_URL } from '../config/config';

const ITEM_TYPE = {
  Folder: 'Folder',
  Document: 'Document',
  PageUrl: 'page_with_url',
};

const ploneAPI = Axios.create({
  baseURL: `${PLONE_URL}`,
  headers: {
    // Authorization: `Basic ${encodedAuth}`,
    Accept: 'application/json',
  },
});

const getFolderData = async (URL, root) => {
  if (!root) {
    root = {};
  }
  const custData = [];
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  await Promise.all((resp.data.items || []).map(async (pItem) => {
    if (pItem['@type'] === ITEM_TYPE.Document) {
      const sitePath = `${PLONE_URL}/allianceair/en/`;
      let groupPaths = pItem['@id'].replace(sitePath, '').split('/');
      groupPaths = groupPaths.slice(0, -1);
      // need to revisit
      const returnObj = {
        page: groupPaths[0],
        section: groupPaths[1] ? groupPaths : '',
        text: pItem.title,
        link: pItem.app_path,
      };
      custData.push(returnObj);
    }
  }));
  console.log(custData);
  return custData;
};

const globalSearch = (URL, searchParam) => {
  if (searchParam) {
    URL += searchParam;
    URL += '&metadata_fields=app_path&metadata_fields=description';
  }
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!URL) return;
    const fun = async () => {
      let response = null;
      setIsLoading(true);
      try {
        response = await getFolderData(URL);
      } catch (e) {
        response = e;
      }
      console.log({ response });
      setData(response);
      setIsLoading(false);
    };
    fun();
  }, [URL]);

  return { data, isLoading };
};

export default globalSearch;
