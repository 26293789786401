/* eslint-disable no-param-reassign */
import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import {
  PLONE_URL, PLONE_SITE,
} from '../config/config';
import { LanguageContext } from '../context/LanguageContext';

const ITEM_TYPE = {
  Folder: 'Folder',
  Document: 'Document',
};

const ploneAPI = Axios.create({
  baseURL: `${PLONE_URL}`,
  headers: {
    // Authorization: `Basic ${encodedAuth}`,
    Accept: 'application/json',
  },
});

const getItemData = async (item) => {
  const resp = await ploneAPI.get(item['@id'].replace(PLONE_URL, ''));
  return resp.data;
};

const getDocumentData = async (item) => {
  const resp = await ploneAPI.get(item.replace(PLONE_URL, ''));
  return { title: resp.data.title, text: resp.data.text };
};

const getFolderData = async (URL, root) => {
  if (!root) {
    root = {};
  }
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  await Promise.all((resp.data.items || []).map(async (item) => {
    const title = item['@id'].split('/').pop();
    if (item['@type'] === ITEM_TYPE.Folder) {
      root[item.title] = await getFolderData(item['@id'], root[item.title]);
    } if (item['@type'] === ITEM_TYPE.Document) {
      root[title] = await getDocumentData(item['@id']);
    }
  }));
  return root;
};

const getBannerList = async (URL) => {
  const response = [];
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  resp.data.items.forEach((item, index) => response.push({ id: index, image: item['@id'] }));
  return response;
};

const getCarouselList = async (URL) => {
  const response = [];
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  resp.data.items.forEach((item) => response.push({ image: item['@id'] }));
  return response;
};

const getCampaignList = async (URL) => {
  const root = [];
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  await Promise.all((resp.data.items || []).map(async (pItem, index) => {
    const itemData = await getItemData(pItem);
    root.push({
      id: index, image: itemData.image.download, url: itemData.link, text: itemData.button_text,
    });
  }));
  return root;
};

const getPloneContent = (url, dataType = '') => {
  const [language] = useContext(LanguageContext);
  const navigationTree = `/${PLONE_SITE}/${language}/${url}`;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!navigationTree) return;
    const fun = async () => {
      let response = [];
      setIsLoading(true);
      try {
        if (dataType === 'images') {
          response = await getBannerList(navigationTree);
        } else if (dataType === 'carousel') {
          response = await getCarouselList(navigationTree);
        } else if (dataType === 'campaign') {
          response = await getCampaignList(navigationTree);
        } else if (dataType === 'document') {
          response = await getDocumentData(navigationTree);
        } else {
          response = await getFolderData(navigationTree);
        }
      } catch (e) {
        console.log(e);
      }
      setData(response);
      setIsLoading(false);
    };
    fun();
  }, [navigationTree]);

  return { data, isLoading };
};

export default getPloneContent;
