/* eslint-disable no-param-reassign */
import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import {
  PLONE_URL, PLONESITE_URL,
} from '../config/config';
import { LanguageContext } from '../context/LanguageContext';

const ploneAPI = Axios.create({
  baseURL: `${PLONE_URL}`,
  headers: {
    // Authorization: `Basic ${encodedAuth}`,
    Accept: 'application/json',
  },
});

const getNavigationTree = async (URL, lang) => {
  console.log('...getting navigation tree');
  const resp = await ploneAPI.get(URL.replace(PLONE_URL, ''));
  console.log('...getting navigation tree', resp);
  const result = {
    'our-routes': [], 'top-destinations': [], 'footer-links': {}, 'below-footer': [],
  };
  resp.data.items.forEach((item) => {
    const footerPath = `${PLONESITE_URL}/${lang}/footer`;
    if (item['@id'] !== footerPath) {
      const a1 = item['@id'].replace(`${footerPath}/`, '').split('/');
      if (a1.length === 2) {
        if (item['@type'] === 'Folder') {
          if (!result[a1[0]][a1[1]]) {
            result[a1[0]][a1[1]] = { title: item.title, items: [] };
          } else {
            result[a1[0]][a1[1]].title = item.title;
          }
        } else {
          result[a1[0]].push({ title: item.title, url: item.remote_url });
        }
      } else if (a1.length === 3) {
        if (!result[a1[0]][a1[1]]) {
          result[a1[0]][a1[1]] = { items: [] };
          result[a1[0]][a1[1]].items.push({ title: item.title, url: item.remote_url });
        } else {
          result[a1[0]][a1[1]].items.push({ title: item.title, url: item.remote_url });
        }
      }
    }
  });
  result['footer-links'] = Object.values(result['footer-links']);
  Object.keys(result).map((item) => {
    if (item === 'footer-links') {
      result[item].map((x, i) => {
        x.id = i;
        x.items.map((y, idx) => { y.id = idx; return y; });
        return x;
      });
    } else {
      result[item].sort((a, b) => a.title.localeCompare(b.title)).map((x, i) => {
        x.id = i;
        return x;
      });
    }
    return result[item];
  });
  return result;
};

const useGetFooter = () => {
  console.log('...get footer');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  try {
    const [language] = useContext(LanguageContext);
    const navigationTree = `/allianceair/${language}/@search?b_size=100&path.query=%2Fallianceair%2F${language}%2Ffooter&path.depth=3&metadata_fields=remote_url`;
    useEffect(() => {
      console.log('...get footer in effect');

      if (!navigationTree) return;
      const fun = async () => {
        let response = [];
        setIsLoading(true);
        try {
          console.log('...get footer checks passed');
          response = await getNavigationTree(navigationTree, language);
          console.log('..........', { response });
        } catch (e) {
          console.log(e);
        }
        setData(response);
        setIsLoading(false);
      };
      fun();
    }, [language]);
  } catch (e) {
    console.error('... exception', e);
  }
  return { data, isLoading };
};

export default useGetFooter;
