import React from 'react';
import './FAQItem.scss';
import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'SHExpectantMothers',
    header: 'Until when are Expectant Mothers permitted to fly?',
    body: (
      <>
        <p>
          We understand that pregnancy is not a medical condition unless there are known medical complications which can endanger the life of mother or the child. However, we request you to kindly take note of the following:
        </p>
        <strong>Normal pregnancies:</strong>
        <ul>
          <li>Expectant mothers may be permitted to fly on allianceair flights till the end of 36th week of pregnancy, provided there are no prior complications.</li>
          <li>Fit to Fly certificate from the treating obstetrician, dated not more than 7 days before the date of travel, is required only if the pregnancy is between the 28th and 36th week.</li>

        </ul>
        <strong>Multiple (twins, triplets etc.) pregnancies:</strong>
        <ul>
          <li>Expectant mothers may be permitted to fly on allianceair flights only till the end of 32nd week of pregnancy.</li>
          <li>Fit to Fly certificate from the treating obstetrician, dated not more than 7 days before the date of travel, is required only if the pregnancy is between 28th & 32nd  week.</li>
        </ul>
        <strong>Complicated pregnancies:</strong>
        <ul>
          <li>Expectant mothers shall not be allowed to travel beyond 32nd week of pregnancy in case of complicated pregnancy.</li>
          <li>Fit to Fly certificate from the treating obstetrician, dated not more than 3 days before the date of travel, is required only if the pregnancy is between 28th & 32nd week.</li>
        </ul>
        <p>
          In addition, medical clearance is given by the company doctor who will discuss the case on an individual basis with the passenger’s doctor/or will check all the medical documents of the passenger (pregnant lady) in case treating obstetrician is not contactable.
        </p>

      </>
    ),
  },
  {
    id: 'SHFlyingJrSolo',
    header: 'Who is considered Flying junior Solo (Unaccompanied Minor)?',
    body: (
      <>
        <p>
          Children above the age of two and below the age of twelve years are regarded as Minors. allianceair offers the following assistance services to minors:
        </p>
        <ul>
          <li>Under 5 years - 'Flying Junior Solo' service is unavailable, however, the child MUST travel with someone over the age of 18 years</li>
          <li>5-12 years - 'Flying Junior Solo' service is available and can the child can travel on all flights</li>
          <li>13-18 years - The child can travel alone or we would be happy to offer the 'Flying Junior Solo' service</li>
        </ul>
      </>
    ),
  },
  {
    id: 'SHJrReportingTime',
    header: 'By what time must flying Junior Solo (Unaccompanied Minor) report at the airport?',
    body: (
      <>
        <p>
          All unaccompanied minors must report at the airport at least 2 hours prior to departure for domestic flight and 4 hours prior to departure for international flights
        </p>
      </>
    ),
  },
  {
    id: 'SHJrForm',
    header: 'Do I need to fill any form for my child’s travel with allianceair?',
    body: (
      <>
        <p>
          A parent or guardian is required to complete a signed
          {' '}
          <a href={`${HOME_URL}/documents/UMNR.pdf`}>flying Junior Solo (Unaccompanied Minor) form</a>
          (Form to be downloaded from website) at the point of check-in at each departure airport prior to travel. The form must include the name and contact numbers of the parent or guardian (above the age of 18) who will meet the unaccompanied minor at the point of arrival
        </p>
      </>
    ),
  },
  {
    id: 'SHJrPhotoID',
    header: 'Do I need to provide a valid photo ID proof for my child?',
    body: (
      <>
        <p>
          Valid photo ID for both the unaccompanied minor and the parent/guardian is required at the point of
          {' '}
          <a href="/manage-booking">check-in</a>
        </p>
      </>
    ),
  },
  {
    id: 'SHJrAssistance',
    header: 'What kind of assistance will the child be provided with?',
    body: (
      <>
        <strong>During Travel</strong>
        <p>
          A staff member will take the child to the aircraft where our cabin crew will then look after them.
        </p>
        <strong>On Board</strong>
        <p>
          During the flight, our cabin crew will keep an eye on the child and will make sure that they have everything they need. Do not worry about the snack, as it is included on our Flying Solo services. To make sure that the child has a comfortable flight, the parent/guardian is requested to pack medication, warm clothing and a favourite toy or game in their hand baggage.
        </p>
        <strong>Post Arrival</strong>
        <p>
          When the flight arrives, our ground staff will accompany and assist the child through the airport arrivals process. Please be available at the arrival hall at least 30 mins prior arrival to receive the child.
        </p>

      </>
    ),
  },
  {
    id: 'SHJrParentStay',
    header: 'Does the parent or guardian remain at the airport until the flight has departed?',
    body: (
      <>
        <p>
          Yes
        </p>
      </>
    ),
  },
  {
    id: 'SHJrTransit',
    header: 'Will allianceair accept an flying Junior Solo (Unaccompanied Minor) if there is any transit/transfer?',
    body: (
      <>
        <p>
          If the stopover at transfer station is less than 6 hours and if the child is booked through out on allianceair, we shall be able to accept the child under the 'Flying Junior Solo' Services. If the stopover at transfer stations is more than 6 hours and/or involves night halt and/or transfer is on other airline except allianceair, we shall not be able to accept the child under the 'Flying Junior Solo' services. In such cases, the child must be accompanied by someone who is above the age of 18 years.
        </p>
      </>
    ),
  },
  {
    id: 'SHInfantAge',
    header: 'What is the age of an infant?',
    body: (
      <>
        <p>
          For safety reasons, children above the age of seven 7 days and under the age of two 2 years, as on the date of travel, can travel as Infants. Age proof needs to be provided at the time of check-in.
        </p>
      </>
    ),
  },
  {
    id: 'SHInfantNoProof',
    header: 'What if I don\'t have a valid proof for the infant\'s age?',
    body: (
      <>
        <p>
          In the event of a failure to produce a valid proof of age for the infant, full fare as applicable on the date of booking will be payable for the Infant and the Infant will be allowed to travel only subject to availability of seats.
        </p>
        <p>
          Valid ID proof for Infants:-
        </p>
        <ul>
          <li>Birth Certificate</li>
          <li>Mother's hospital discharge summary</li>
          <li>Vaccination certificate</li>
          <li>Passport</li>
        </ul>

      </>
    ),
  },
  {
    id: 'SHInfantSeat',
    header: 'Do I need to book a separate seat for an Infant?',
    body: (
      <>
        <p>
          No extra seats may be booked for Infants. Infants cannot travel in their own seat and must be seated in an adult's lap.
        </p>
      </>
    ),
  },
  {
    id: 'SHInfantBook',
    header: 'How do I book for an infant?',
    body: (
      <>
        <p>
          Guests traveling with infants are requested to specify the same at the time of booking. In case they forget to do so they are requested to call the Call Center before their date of travel or mention to the executive at the airport check-in counter and we will assist them with the booking. A fee of INR 1299 (per sector is to be paid towards the same. Due to limited inventory for accommodating infants per flight, Guests are requested to follow the above mentioned procedure when booking infants.
        </p>
      </>
    ),
  },
  {
    id: 'SHInfantCount',
    header: 'How many infants can travel per flight?',
    body: (
      <>
        <p>
          A maximum of 4 infants can travel per flight on an ATR. However, only one infant per adult is allowed per flight.
        </p>
      </>
    ),
  },
  {
    id: 'SHInfantBaggageAllowance',
    header: 'What is the baggage allowance for Infant?',
    body: (
      <>
        <p>7 kg is the standard baggage allowance for cabin baggage. Infants are not permitted to check-in luggage.</p>
        <p>One stroller or Baby pram per infant is allowed without any charge.</p>
      </>
    ),
  },
];

export default function SpecialHandling({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              selectedEventKey={eventKey}
              id={item.id}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
