/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { Markup } from 'interweave';
import './Marquee.scss';
import getPloneContent from '../hooks/getContent';

export default function Marquee(props) {
  const { data, isLoading } = getPloneContent('marquee');
  return (
    <div className="marquee-container" style={{ '--pause-on-hover': 'paused', '--pause-on-click': 'paused' }}>
      {
        isLoading && <div> Loading... </div>
      }
      {
        !isLoading && (
<div
        className="marquee"
        style={{
          '--play': 'running', '--direction': 'normal', '--duration': '131.185s', '--delay': '0s', '--iteration-count': 'infinite',
        }}
>
        <Markup content={data[props.type] ? data[props.type].text.data : ''} />
</div>
        )
      }
    </div>
  );
}
