import React, { useState } from 'react';
import { getSession, setSession } from '../config/storage';

const LanguageContext = React.createContext([{}, () => {}]);

const LanguageProvider = (props) => {
  let currentLang = getSession('language');
  if (!currentLang) {
    setSession('language', 'en');
    currentLang = 'en';
  }
  const [state, setState] = useState(currentLang);
  return (
    <LanguageContext.Provider value={[state, setState]}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
