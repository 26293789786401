import React from 'react';
import './FAQItem.scss';
import { Accordion } from 'react-bootstrap';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL, SQIVA_URL } from '../../../config/config';

const data = [
  {
    id: 'GQNameMismatch',
    header: 'Can I be denied boarding if my name or title on the booking does not match the details on my photo identity card?',
    body: (
      <p>
        Yes, boarding can be denied if the name or title on the itinerary does not match the name on the identity card.
      </p>
    ),
  },
  {
    id: 'GQDocuments',
    header: 'Which all documents are accepted for Domestic Flights?',
    body: (
      <p>
        <ul>
          <li>Valid Passport</li>
          <li>Valid Driving License</li>
          <li>Election Photo Identification Card</li>
          <li>PAN card issued by the Income Tax department</li>
          <li>Photo identity card issued by the employer, being government and reputed private sector organisations</li>
          <li>Children travelling on allianceair should carry their school identification cards or any other photo identification proof</li>
          <li>Valid birth certificates of infants need to be produced at the time of check-in</li>
          <li>For Foreign Nationals who travel on domestic flights, the only valid photo ID for travel is Passport.</li>
        </ul>

        There shall not be any requirement of identity proof in respect of Infant/Minor accompanied by their parents/guardian who is a bonafide passenger having a valid identity proof as listed above. However, an unaccompanied minor shall be required to produce a valid identity proof as listed above.
      </p>
    ),
  },
  {
    id: 'GQDocumentsForeignNational',
    header: 'Which all documents are accepted for Foreign Nationals ?',
    body: (
      <>
        <p>The only valid photo ID for travel is Passport.</p>
        <p>Guest’s passport must be valid for at least six (6) months from the date of travel.</p>
      </>
    ),
  },
  {
    id: 'GQDocumentImphal',
    header: 'Is there any additional document required for entering Imphal?',
    body: (
      <>
        <p>
          All foreign nationals travelling to Imphal only are required to carry a Protected Area Permit, issued by the Home Department of Manipur after getting the approval from Ministry of Home, Govt of India. The Permit also specifies the passport number and duration of stay in the stated city. As per the Govt of India directive allianceair is entitled to deny boarding rights to any Foreign National in the absence of a valid Protected Area Permit and the entire amount would be forfeited.
        </p>
        <strong>For Nationals of any other country</strong>
        <p>
          For nationals of any other country, passport is the only valid document for travel.
        </p>
      </>
    ),
  },
  {
    id: 'GQNoTicket',
    header: 'The amount has been deducted from my account however, I have not received the ticket?',
    body: (
      <>
        <p>
          Amount may be deducted from your account but the ticket has not been generated:
        </p>
        <p>The amount received by us will automatically be refunded within 5 business days</p>
        <p>In case it is not refunded then you may share the following Information with our call center team or contact our guest relations team via email (groups.relations@allianceair.in):</p>

        <ul>
          <li>Transaction ID sent by allianceair via email at the time of initiating the payment along with amount, date and time of transaction OR</li>
          <li>PGI Reference number along with amount, date and time of transaction OR</li>
          <li>Scanned copy of bank statement</li>
        </ul>
      </>
    ),
  },
  {
    id: 'GQPrintOut',
    header: 'How can I get a print out of my ticket/itinerary?',
    body: (
      <>
        <p>Click on View/ChangeBooking(click here link) on our home page and retrieve your reservation by entering your PNR (a six digit code generally sent as an SMS after you make a reservation) and email address or last name. Once it is reservation is retrieved you can take a print out of your ticket.</p>
        <p>Note: If you have made the reservation through a travel agency you may have to get your email address updated in order to retrieve the booking online.</p>
      </>
    ),
  },
  {
    id: 'GQPrintBoardingPass',
    header: 'How can I print/re-print my Web Check-in boarding pass?',
    body: (
      <p>
        If you have already Web Check-in, you will need to click on the (www.allianceair.in\web-check-in) link on our home page and retrieve your reservation using your PNR (a six digit code generally sent as an SMS after you make a reservation) or last name. Once your reservation is retrieved you can print or reprint your boarding pass.
      </p>
    ),
  },
  {
    id: 'GQBookSeat',
    header: 'How can I book a specific seat for myself on the flight?',
    body: (
      <p>
        Passengers can book specific seats on the flight through our website or at the airport.
      </p>
    ),
  },
  {
    id: 'GQPriorityCheckin',
    header: 'What is Priority Check-in Service?',
    body: (
      <>
        <p>allianceair offers Priority Check-in Service for a hassle free experience at the airport. This Service helps passengers save time by getting their luggage on priority at arrivals.</p>
        <p>
          The priority check-in service can be purchased through allianceair’s website (
          <a href={HOME_URL}>www.allianceair.in</a>
          ) and Call center until two (2) hours prior to domestic departures and at the airport up until the closure of the check-in process for the relevant flight for which the passenger intends to purchase the service with a charge of INR 250 all inclusive.
        </p>
      </>
    ),
  },
  {
    id: 'GQOnBoardPurchase',
    header: 'On-board purchases:',
    body: (
      <p>
        We accept cash payments for on-board purchases.
      </p>
    ),
  },
  {
    id: 'GQAlcohol',
    header: 'Is alcohol offered on-board allianceair?',
    body: (
      <p>
        As per DGCA regulations serving and consumption of alcohol is strictly prohibited on Domestic flights.
      </p>
    ),
  },
  {
    id: 'GQSmoking',
    header: 'Is smoking allowed on- board allianceair flights?',
    body: (
      <p>
        Smoking is strictly prohibited on any allianceair aircraft
      </p>
    ),
  },
  {
    id: 'GQFoodWater',
    header: 'Can I carry water and food on-board?',
    body: (
      <>
        <p>allianceair provides water cups free of charge on all its flights.</p>
        <p>allianceair allows passengers to bring food items on-board. Allowed food items include cold snacks, non-alcoholic drinks, snack bars and biscuits.</p>
        <p>Messy, oily or strong smelling food items are not allowed on-board</p>
      </>
    ),
  },
  {
    id: 'GQEarPlugs',
    header: 'Does allianceair provide ear plugs and cotton?',
    body: (
      <p>
        allianceair currently does not provide ear plugs and cotton on-board. Therefore, we recommend that passengers carry their own chewing gums, cotton and/or ear plugs for their comfort. It is also recommended that passengers carry blankets/ shawl on board. We would be serving candies on board (the ones you can keep in your mouth and suck, it helps in releasing pressure from the ears, although not recommended for our little guests)
      </p>
    ),
  },
  {
    id: 'GQMedicalAssistance',
    header: ' Will the cabin crew be able to provide me with any medical assistance?',
    body: (
      <>
        <p>allianceair would like to mention that our cabin crews are not authorized to give special assistance (e.g. lifting etc.). Further they are trained only in first-aid and are not permitted to administer any injections or to give any medication (The crew are trained first aiders and do administer certain medications orally / application from the on-board First Aid Kit).</p>
        <p>
          A guest who has agreed to our condition of carriage (www.allianceair.in/coc) agrees and understands that he/she has accepted to undertake the travel on allianceair on his/her own will and volition, and further agrees that allianceair will not be responsible for any liability other than the liability as specified in the condition of carriage (
          <a href={`${HOME_URL}/coc`}>www.allianceair.in/coc</a>
          )
        </p>
      </>
    ),
  },
  {
    id: 'GQAircraftType',
    header: 'What type of aircraft does allianceair fly?',
    body: (
      <p>
        allianceair operates the ATR -72 500 aircraft. The seating capacity on the ATR fleet is 72.
      </p>
    ),
  },
  {
    id: 'GQDestinations',
    header: 'What all cities does allianceair fly to?',
    body: (
      <p>
        allianceair has a total destination count of 4 with four domestic destinations. This includes Indore, Bhopal, Jabalpur and Raipur. – this will keep Changing as we keep adding destinations in near future.
      </p>
    ),
  },
];

export default function GeneralQueries({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              selectedEventKey={eventKey}
              id={item.id}
              header={item.header}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
