import React from 'react';
import './Copyright.scss';

export default function Copyright() {
  return (
    <div className="footer">
      <h5>Copyright © 2021 Alliance Air. All rights reserved.</h5>
      {/* <h5>No. of Visitors: 00XXXXX</h5> */}
    </div>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
