import React from 'react';
import CardHolder from '../../../components/CardHolder';
import { HOME_URL } from '../../../config/config';
import '../FAQItems/FAQItem.scss';

const data = [
  {
    id: 'SanitiseAircraft',
    header: 'Is allianceair taking any precautions to sanitise their aircraft?',
    body: (
      <p>
        The safety or our guests and employees is of utmost priority in these trying times. The aircraft are being cleaned using the Environmental Protection Agency (EPA) approved hospital-grade disinfectant. The deep cleaning of the aircraft includes the cabin, on elements in the flight deck, and in the lavatory. Additionally, allianceair has also taken cleaning measures at airports and across allianceair offices. To know more about allianceair’s precautionary measure against Covid-19,
        <a href={`${HOME_URL}/safetymeasures`}>www.allianceair.in/safetymeasures</a>
      </p>
    ),
  },
  {
    id: 'quarantineState',
    header: 'What are the quarantine rules for the destination state?',
    body: (
      <p>
        Each state/city has come with its own guidelines and protocols for air travellers. You should check these guidelines and regulation of your destination state before booking the flight. You can check all the state-wise regulations
        <a href={`${HOME_URL}/documents/StateRegulatory.pdf`} rel="noreferrer" target="_blank">www.allianceair.in/stateregulatories.</a>
      </p>
    ),
  },
  {
    id: 'highTemperature',
    header: 'What if I am not allowed to fly due to high temperature recorded during thermal screening?',
    body: (
      <p>
        If during thermal screening, you are detected with high temperature, then you will not be allowed to board the flight. In such a case, you can reschedule the flight for another date.
      </p>
    ),
  },
  {
    id: 'travelFitness',
    header: 'How can I declare I am fit for air travel?',
    body: (
      <p>
        To declare that you are fit for travel, you can update your health status on the Aarogya Setu app, and fill the health declaration form.
      </p>
    ),
  },
  {
    id: 'containmentZone',
    header: 'Am I allowed to fly if I am from a containment zone?',
    body: (
      <p>
        If you are from a containment zone, then you should avoid travelling.
      </p>
    ),
  },
  {
    id: 'sanitiseLuggage',
    header: 'Will allianceair sanitise the luggage while loading and off-loading?',
    body: (
      <p>
        It is advisable to self-sanitise the luggage. However, all airports and airlines, including allianceair, have enhanced their preventive measures to safeguard and protect the health of the passengers.
      </p>
    ),
  },
  {
    id: 'socialDistancing',
    header: 'What is social distancing? Why is it important?',
    body: (
      <p>
        Social distancing or physical distancing is where people are advised to avoid crowds and stay at home. This helps in limiting the spread of pathogens from a virus. WHO advises maintaining a distance of at least 1 metre from another. To know more,
        {' '}
        <a href={`${HOME_URL}/linkhere`}>click here</a>
        {' '}
        to refer the advisory on social distancing by the Ministry of Health and Family Welfare.
      </p>
    ),
  },
  {
    id: 'tempearatureChecks',
    header: 'Are temperature checks in place at Airports?',
    body: (
      <p>Yes. Thermal screening is in place at Airports.</p>
    ),
  },
];

export default function Others({ eventKey }) {
  return (
    <div className="faqitem">
      {/* <Accordion defaultActiveKey={eventKey}> */}
      {
          data.map((item) => (
            <CardHolder
              eventKey={item.id}
              id={item.id}
              header={item.header}
              selectedEventKey={eventKey}
            >
              {item.body}
            </CardHolder>
          ))
        }
      {/* </Accordion> */}
    </div>
  );
}
