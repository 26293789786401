import React from 'react';
import {
  Row, Col, Form, Button, Image,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './Dynamic.scss';
import { Markup } from 'interweave';
import getPloneContent from '../../hooks/getContent';

export default function Dynamic() {
  const location = useLocation();
  const { data, isLoading } = getPloneContent(`pages${location.pathname}`, 'document');
  console.log(data);
  return (
    <div>
      <h4>
        {data.title}
      </h4>
      {/* {data} */}
      {
        data && data.text && data.text.data && (
          <div className="dynamic-container">
            <Markup content={data.text.data} />
          </div>
        )
      }

    </div>
  );
}
