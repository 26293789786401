export const HOME_URL = 'https://allianceair.in';
export const SQIVA_URL = 'https://bookme.allianceair.in';
export const PARTNER_LOGIN = 'https://paxlinks.com/?&domain=allianceair.in';
export const FACEBOOK_URL = 'https://www.facebook.com/allianceair.in/';
export const INSTAGRAM_URL = 'https://www.instagram.com/allianceair.9i/?hl=en';
export const TWITTER_URL = 'https://twitter.com/allianceair?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/allianceairaviationlimited/posts/?feedView=all';
export const PLONE_URL = 'https://plone.allianceair.in';
export const PLONE_SITE = 'allianceair';
export const PLONESITE_URL = `${PLONE_URL}/${PLONE_SITE}`;
