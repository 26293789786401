/* eslint-disable import/order */
/* eslint-disable jsx-a11y/alt-text */

import './Header.scss';

import {
  Form, InputGroup, Row, Button, Dropdown, ToggleButtonGroup, ToggleButton,
} from 'react-bootstrap';
import { HOME_URL, PARTNER_LOGIN } from '../config/config';
import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { FaSearch } from 'react-icons/fa';
import AllianceAirNavbar from './Navbar';
import { PHONE_NUMBER, EMAIL } from '../constants';
import { __RouterContext } from 'react-router';
import { LanguageContext } from '../context/LanguageContext';
import Marquee from './Marquee';
import { getSession, setSession } from '../config/storage';

export default function Header() {
  const [searchText, setSearchText] = useState('');
  const [showAlert, setShowAlert] = useState(getSession('cookie-notify') === 'true');
  const [showSearch, setShowSearch] = useState(false);
  const { location } = useContext(__RouterContext);
  const isHeaderIframe = location.pathname.toLowerCase().includes('headerframe');
  const [language, setLanguage] = useContext(LanguageContext);

  const searchHandler = () => {
    if (showSearch && searchText === '') {
      setShowSearch(false);
    }
    if (!showSearch) {
      setShowSearch(true);
    }
  };

  return (
    <div style={{ position: 'relative' }}>

      <div className="info-header hidden-sm">
        <nav className="navbar mb-0">
          <div>
            <Modal
              show={showAlert}
              dialogClassName={isHeaderIframe ? 'custom-modal' : ''}
              onHide={() => {
                setShowAlert(false);
              }}
            >
              <Modal.Body>
              By continuing to use our website, you are consenting the use of cookies for providing you optimum site performance, best user experience and tracking usage of our website to improve our product & services offerings. You can find more related information in our&nbsp;
              <a href="https://plone.allianceair.in/allianceair/en/assets/aaal_customer_data_privacy_policy.pdf" rel="noreferrer" target="_blank" className="linkText">
              Customer Data Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a href="https://plone.allianceair.in/allianceair/en/assets/aaal_terms_and_conditions.pdf" rel="noreferrer" target="_blank" className="linkText">
                Terms and Conditions.
              </a>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cookie-modal-btn"
                  onClick={() => {
                    setShowAlert(false);
                    setSession('cookie-notify', 'false');
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="ibe-navbar top-header">
            <div className="row px-3 pt-3" style={{ justifyContent: 'space-between' }}>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <Marquee type="top-marquee" />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8 pb-3">
                <div className="row ibe-contact float-right">
                    <span>
                      Alliance Air Customer Care:
                      {' '}
                      {PHONE_NUMBER}
                      <span className="seperator">|</span>
                      {EMAIL}
                      <span className="seperator">|</span>
                    </span>
                    <span>
                      <img src="/ind-flag.png" style={{ width: '22px' }} />
                      {' '}
                      India
                      <span className="seperator">|</span>
                    </span>
                    <span>
                      <ToggleButtonGroup name="radio">
                        <ToggleButton
                              key="hi"
                              id="radio-hi"
                              type="radio"
                              variant={language === 'hi' ? 'primary' : 'secondary'}
                              name="radio"
                              value="hi"
                              checked={language === 'hi'}
                              onChange={(e) => {
                                setSession('language', e.currentTarget.value);
                                setLanguage(e.currentTarget.value);
                              }}
                        >
                              Hindi
                        </ToggleButton>
                        <ToggleButton
                              key="en"
                              id="radio-en"
                              type="radio"
                              variant={language === 'en' ? 'primary' : 'secondary'}
                              name="radio"
                              value="en"
                              checked={language === 'en'}
                              onChange={(e) => {
                                setSession('language', e.currentTarget.value);
                                setLanguage(e.currentTarget.value);
                              }}
                        >
                              English
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <span className="seperator">|</span>
                    </span>
                    <Form
                      target={isHeaderIframe ? '_top' : ''}
                      method="GET"
                      action="/search"
                      onSubmit={(e) => {
                        if (!isHeaderIframe) {
                          e.preventDefault();
                          const win = window.open(`/search?searchText=${searchText}`, '_self');
                          win.focus();
                        }
                      }}
                      style={{ height: '24px' }}
                    >
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search here..."
                          name="searchText"
                          value={searchText}
                          onChange={(e) => {
                            e.preventDefault();
                            setSearchText(e.target.value);
                          }}
                          style={{ display: showSearch ? '' : 'none', marginRight: '4px' }}
                        />
                        <InputGroup.Prepend style={{ display: 'block' }}>
                          <FaSearch style={{ cursor: 'pointer' }} onClick={searchHandler} />
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Form>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="main-header">
        <nav className="navbar navbar-static-top">
          <div className="ibe-navbar">
            <div className="navbar-header">
              <a target="_parent" href="/book">
                <img src="/aaallogo.png" style={{ height: '50px' }} className="logo-container" />
              </a>
            </div>
            <Row style={{ borderRadius: '8px 0px 0px 8px', justifyContent: 'flex-end' }}>
              <AllianceAirNavbar />
            </Row>
          </div>
        </nav>
      </div>

      {/* <Row>
        <Col xs={1} classNameName="allianceair-navbar" style={{ borderRadius: '0px 8px 8px 0px' }} />
        <Col xs="auto">
          <Image style={{ height: '40px', margin: '0px' }} src="/allianceair.png" />
        </Col>

      </Row>
      <SocialTray /> */}
    </div>
  );
}
/** *************************************************************************
* COPYRIGHT NOTICE:
*  COPYRIGHT 2020 Rockwell International.
*  All rights reserved under copyright laws.  Proprietary
*  and confidential material.  Distribution, use and
*  disclosure restricted by Rockwell International.
************************************************************************* */
